import Navbar from "./components/Nav/landingnav2";
import { Icon } from "@iconify/react";
// import backgroundImage from "./assets/bg4.jpg"
import bgvideo from "./assets/bgvd.mp4"
import bgvideo2 from "./assets/video.mp4"
import bg1 from "./assets/1.jpg"
import bg2 from "./assets/2.jpg"
import bg3 from "./assets/3.jpg"
import bg4 from "./assets/4.jpg"
import bg5 from "./assets/5.jpg"
import bg6 from "./assets/6.jpg"
import backgroundImage2 from "./assets/nini.jpg"
import PropTypes from 'prop-types';

// import Studentsform from "./components/Login/userform";
// import { Link } from "react-router-dom";
import Footer from "./components/Footer";

const FeatureCard = ({ title, description }) => (
  <div className="bg-white p-6 mb-4 shadow-md ">
    <h3 className="text-xl font-bold mb-2 text-primary"><Icon icon="line-md:chevron-small-right" />{title}</h3>
    <p className="text-primary">{description}</p>
  </div>
);

FeatureCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
const LandingPage = () => {


  return (
<>
    <Navbar transparent/>
    <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center" id="home">
          {/* <div
           className="absolute top-18 w-full h-full bg-center bg-cover bg-fixed"
           style={{
             backgroundImage: `url(${backgroundImage})`,
           }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-80 bg-primary"
            ></span>
          </div> */}
         <video
        className="absolute top-0 left-0 w-full h-full object-cover fixed"
        autoPlay
        loop
        muted
      >
        <source src={bgvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Background Color Overlay */}
      <div
        className="absolute top-0 left-0 w-full h-full bg-primary opacity-60"
      ></div>

          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="">
                    <br></br>
                    <br></br>
                  <h1 className="text-white font-semibold text-3xl">
                  Welcome to CRAT - Empowering Your Digital Future
                  </h1>
                  <p className="text-base tracking-wider text-white p-6 font-light"> Embark on a transformative journey with <b>CRAT</b>, 
                  where innovation meets expertise to shape the future of your digital endeavors. 
                  As a trailblazing IT solutions provider, we take pride in being the catalyst for
                   your success, offering dynamic and cutting-edge services 
                   that redefine the possibilities in the digital landscape.</p>
                  <h1 className="text-white font-semibold mb-0 text-2xl underline underline-offset-8">
                 
                  </h1>
                  <p className="text-base tracking-wider text-white p-6 font-light">Discover a comprehensive suite of IT services tailored
                   to meet the evolving demands of the digital landscape. From cutting-edge 
                   software development to robust cybersecurity solutions, 
                   we leverage the latest technologies to drive your success.</p>
                   <h1 className="text-white font-semibold mb-0 text-2xl pb-4">
                   Why Choose CRAT?
                 </h1>
                 <div className="flex flex-wrap bg-white w-full mb-8 shadow-lg rounded-t-lg">
                 <div className="w-full md:w-4/12 px-4 text-center">
                <div className="text-primary relative flex flex-col min-w-0 break-words ">
                  <div className="px-4 py-5 flex-auto">
                 
                    <div className="text-primary text-9xl text-center inline-flex items-center justify-center w-12 h-12 mb-2 ">
                    <Icon icon="ic:baseline-accessibility-new" />
                    </div>
                    <h6 className="text-base  pb-2 font-semibold">Innovation at the Core</h6>
                    <p className="mt-2 mb-4 ">We stay ahead of the curve, embracing emerging technologies to deliver solutions that stand out in a dynamic market.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="text-white bg-primary  relative flex flex-col min-w-0 break-words">
                  <div className="px-4 py-5 flex-auto">
                 
                    <div className="text-white text-9xl text-center inline-flex items-center justify-center w-12 h-12 mb-2 ">
                    <Icon icon="material-symbols:nest-wake-on-approach" />
                    </div>
                    <h6 className="text-base pb-2  font-semibold">Client-Centric Approach</h6>
                    <p className="mt-2 mb-4 ">Your success is our priority. We collaborate closely with you, understanding your unique needs to provide personalized, effective solutions.
                    </p>
                   
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="text-primary relative flex flex-col min-w-0 break-words ">
                  <div className="px-4 py-5 flex-auto">
                 
                  <div className="text-primary text-9xl text-center inline-flex items-center justify-center w-12 h-12 mb-2 ">
                  <Icon icon="mdi:security-lock-outline" />
                    </div>
                    <h6 className="text-base  pb-2 font-semibold">Security You Can Trust</h6>
                    <p className="mt-2 mb-4 ">Safeguard your digital assets with our top-notch cybersecurity measures. Your data integrity and privacy are non-negotiable for us.
                    </p>
                   
                  </div>
                </div>
              </div>
            </div>
           
                 <a href="#service" className="text-white font-semibold mb-0 pt-6 text-6xl text-center inline-flex items-center justify-center" id="service">
                 <Icon icon="line-md:download-outline-loop" />
                 </a>
                 <h1 className="text-white font-semibold mb-0 pt-6 text-2xl" >
           
           Our services 
          
           </h1>
                </div>
              </div>
            </div>
          </div>
         
        </div>

        <section className="pb-20 -mt-0 ">

          
           
          <div className="container mx-auto " >
            <div className="flex flex-wrap">


            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center ">
                <div className="hover:text-white bg-white border-solid border border-cd1 hover:bg-cd1 text-cd1 relative flex flex-col min-w-0 break-words  w-full mb-8">
                  <div>
                  <img className="w-full h-32 object-cover " src={bg4} alt="Service" />
                  </div>
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-base  font-semibold">Software Development:</h6>
                    <p className="mt-2 mb-4 ">
                    Transform your vision into reality with our bespoke software development services. 
                    Our seasoned team of developers specializes in creating cutting-edge applications 
                    tailored to meet your unique business requirements.From conceptualization and design 
                    to deployment and maintenance, we guide you through every stage of the development process.
                     Elevate user experiences and streamline operations with our innovative and scalable software solutions.
                    </p>
                    <p className="underline text-base mt-5">
                      <a href="#register" title="Meet our doctors" className="">
                      Get in Touch
                       </a>
                    </p>

                 </div>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-4 text-center">
                

              <div className="hover:text-white bg-white border-solid border border-cd3 hover:bg-cd3 text-cd3 relative flex flex-col min-w-0 break-words  w-full mb-8 ">
                <img className="w-full h-32 object-cover" src={bg6} alt="Service" />
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-base  font-semibold">Cloud Solutions</h6>
                    <p className="mt-2 mb-4 ">
                    Embrace the future of computing with our customized cloud solutions. Whether you&apos;re a startup looking for cost-effective scalability or an enterprise aiming for operational efficiency, our cloud services are designed to meet your unique needs. We optimize cloud infrastructure, ensuring seamless migration, enhanced performance, and reliable data storage. Elevate your business agility with our tailored cloud solutions.
                    </p>
                    <p className="underline text-base mt-5">
                      <a href="#register" title="Meet our doctors" className="">
                      Get in Touch
                       </a>
                    </p>
                  </div>
                </div>


              </div>

              

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">

              <div className="hover:text-white bg-white border-solid border border-cd2 hover:bg-cd2 text-cd2 relative flex flex-col min-w-0 break-words  w-full mb-8 ">
                <img className="w-full h-32 object-cover" src={bg5} alt="Service" />
                  <div className="px-4 py-5 flex-auto">
                    
                    <h6 className="text-base  font-semibold">Mobile App Development</h6>
                    <p className="mt-2 mb-4 ">Extend your reach and enhance user engagement with our mobile app development services. 
                    Our skilled app developers create intuitive, feature-rich mobile applications for iOS and Android platforms.</p><p className="mt-2 mb-4 ">  Whether you&apos;re launching a
                     consumer-facing app or optimizing internal processes, we focus on delivering seamless experiences that captivate users and drive business results.
                    </p>
                    <p className="underline text-base mt-5">
                      <a href="#register" title="Meet our doctors" className="">
                      Get in Touch
                       </a>
                    </p>
                  </div>
                </div>
               
              </div>



            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center ">
            <div className="hover:text-white bg-white border-solid border border-cd4 hover:bg-cd4 text-cd4 relative flex flex-col min-w-0 break-words  w-full mb-8 ">
                  
                  <img className="w-full h-32 object-cover" src={bg1} alt="Service" />
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-base  font-semibold">Software Development:</h6>
                    <p className="mt-2 mb-4 ">
                    Transform your vision into reality with our bespoke software development services. 
                    Our seasoned team of developers specializes in creating cutting-edge applications 
                    tailored to meet your unique business requirements. From conceptualization and design 
                    to deployment and maintenance, we guide you through every stage of the development process.
                     Elevate user experiences and streamline operations with our innovative and scalable software solutions.
                    </p>
                    <p className="underline text-base mt-5">
                      <a href="#register" title="Meet our doctors" className="">
                      Get in Touch
                       </a>
                    </p>

                 </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
              <div className="hover:text-white bg-white border-solid border border-cd4 hover:bg-cd4 text-cd4 relative flex flex-col min-w-0 break-words  w-full mb-8 ">
                 
                  <img className="w-full h-32 object-cover" src={bg2} alt="Service" />
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-base  font-semibold">Mobile App Development</h6>
                    <p className="mt-2 mb-4 ">Extend your reach and enhance user engagement with our mobile app development services. 
                    Our skilled app developers create intuitive, feature-rich mobile applications for iOS and Android platforms. Whether you&apos;re launching a
                     consumer-facing app or optimizing internal processes, we focus on delivering seamless experiences that captivate users and drive business results.
                    </p>
                    <p className="underline text-base mt-5">
                      <a href="#register" title="Meet our doctors" className="">
                      Get in Touch
                       </a>
                    </p>
                </div>
                </div>
              </div>

              

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="hover:text-white bg-white border-solid border border-cd4 hover:bg-cd4 text-cd4 relative flex flex-col min-w-0 break-words  w-full mb-8 ">
                 
                  <img className="w-full h-32 object-cover" src={bg3} alt="Service" />
                  <div className="px-4 py-5 flex-auto">
                    <h6 className="text-base  font-semibold">Cloud Solutions</h6>
                    <p className="mt-2 mb-4 ">
                    Embrace the future of computing with our customized cloud solutions. Whether you&apos;re a startup looking for cost-effective scalability or an enterprise aiming for operational efficiency, our cloud services are designed to meet your unique needs. We optimize cloud infrastructure, ensuring seamless migration, enhanced performance, and reliable data storage. Elevate your business agility with our tailored cloud solutions.
                    </p>
                    <p className="underline text-base mt-5">
                      <a href="#register" title="Meet our doctors" className="">
                      Get in Touch
                       </a>
                    </p>
                 </div>
                </div>
              </div>

              

    

            </div>

          </div>
        </section>
  

    <div className="relative pt-16 pb-32 flex content-center items-center justify-center" id="about">
          {/* <div
           className="absolute top-18 w-full h-full bg-center bg-cover bg-fixed"
           style={{
             backgroundImage: `url(${backgroundImage})`,
           }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-80 bg-primary"
            ></span>
          </div> */}
         <video
        className="absolute top-0 left-0 w-full h-full object-cover fixed"
        autoPlay
        loop
        muted
      >
        <source src={bgvideo2} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Background Color Overlay */}
      <div
        className="absolute top-0 left-0 w-full h-full bg-primary opacity-90"
      ></div>

          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="">
                    <br></br>
                    <br></br>
                  <h1 className="text-white font-semibold text-3xl">
                  About us 
                  </h1>
                  <p className="text-base tracking-wider text-white p-6 font-light"> CRAT is an Information technology and related services company, providing world class custom driven integrated solutions to its customers.</p>
                  <h1 className="text-white font-semibold mb-0 text-2xl underline underline-offset-8">
                 
                  </h1>
                  <p className="text-base tracking-wider text-white p-6 font-light">Our team comprises seasoned professionals with diverse expertise in software development, graphics design and information security, artificial intelligence and more. With a relentless commitment to excellence and customer-centric approach, we strive to exceed expectations in every project we undertake.</p>
                   <h1 className="text-white font-semibold mb-0 text-2xl pb-4">
                   Mission & Vision 
                 </h1>
                 <div className="flex flex-wrap bg-white w-full mb-8 shadow-lg ">
                 <div className="w-full md:w-6/12  text-center">
                <div className="text-white bg-primary  relative flex flex-col min-w-0 break-words ">
                  <div className="px-4 py-5 flex-auto">
                 
                    <div className="text-white text-9xl text-center inline-flex items-center justify-center w-12 h-12 mb-2 ">
                    <Icon icon="line-md:speedometer-loop" />
                    </div>
                    <h6 className="text-base  pb-2 font-semibold">Mission</h6>
                    <p className="mt-2 mb-4 ">Our mission is to empower businesses and individuals by delivering innovative technology solution that drive growth, efficient, and success. We are committed to excellence in everything we do, from software development and IT consulting to Cybersecurity and digital transformation. With a focus on integrity, collaboration, and customer satisfaction, we strive to be a trusted partner and a catalyst for positive change in the digital world. 
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-6/12 px-4 text-center">
                <div className="text-primary  relative flex flex-col min-w-0 break-words">
                  <div className="px-4 py-5 flex-auto">
                 
                    <div className="text-primary text-9xl text-center inline-flex items-center justify-center w-12 h-12 mb-2 ">
                    <Icon icon="line-md:sun-rising-loop" />
                    </div>
                    <h6 className="text-base pb-2  font-semibold">Vision </h6>
                    <p className="mt-2 mb-4 ">Our vision is to be a global leader in technological innovation, driving positive change and shaping the future of industries worldwide. </p>
                   
                  </div>
                </div>
              </div>

            </div>
            

                </div>
              </div>
            </div>
          </div>
         
        </div>


        <div className="relative pt-16  flex content-center items-center justify-center min-h-screen-85" id="contactus">
          <div
           className="absolute top-18 w-full h-full bg-center bg-cover bg-fixed"
           style={{
             backgroundImage: `url(${backgroundImage2})`,
           }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-primary"
            ></span>
          </div>

<div className="container relative  mx-auto text-white ">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="">
                    
                  <h1 className="text-white mb-10 font-semibold text-3xl">
                  Get in touch with us</h1>
                  <div className="container mx-auto p-4 flex flex-col md:flex-row justify-between">

                  <div className="md:w-1/2 mt-4 md:mt-0 md:pr-8 gap-4 justify-items-start">
        
        <div className="flex ml-5 mt-10">
                <Icon icon="clarity:map-marker-line" width="30" />
                  <h2 className="font-light ml-5  tracking-wide md:text-center xs:text-center ">Rubavu, Rwanda</h2>
                </div>
                <div className="flex ml-5  mt-5">
    
    <Icon icon="clarity:email-line" width="30" />
    
      <h2 className="  font-light ml-5  tracking-wide md:text-center xs:text-center ">info@crt.rw</h2>
    </div>
    <div className="flex ml-5 mt-5">
      <Icon icon="fluent:call-24-regular" width="30" />
      <h2 className=" ml-5  font-light tracking-wide md:text-center xs:text-center ">+250-785-599-926</h2>
    </div>
          </div>
       
                  <div className="md:w-1/2 md:pr-8">
        
                  <form >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        
        <div className="col-span-1">
          <label htmlFor="lastName" className="block text-sm font-medium ">Names</label>
          <input type="text" name="lastName" id="lastName"  className="mt-1 p-2  border border-gray-300 focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500 block w-full" required />
        </div>
        <div className="col-span-1">
          <label htmlFor="email" className="block text-sm font-medium ">Email</label>
          <input type="email" name="email" id="email"  className="mt-1 p-2  border border-gray-300 focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500 block w-full" required />
        </div>
        <div className="col-span-1">
          <label htmlFor="pin" className="block text-sm font-medium ">Message</label>
          <textarea  className="mt-1 p-2  border border-gray-300 focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500 block w-full" required />
        </div>
        
        <button type="submit" className="mt-4 px-4 py-3 bg-white text-primary hover:bg-primary hover:opacity-50 hover:text-white border hover:border-white border-primary focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50 items-center text-center flex">
      <i className='mr-2'><Icon icon="grommet-icons:send" /></i> Submit
      </button>
          
      </div>
      
    </form>
    </div>
   
    </div>

                </div>
              </div>
            </div>
          </div>

    </div>
      
        <section className="relative mt-10 block lg:pt-0 bg-blueGray-800" id="training">
         

        <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-2xl p-8 bg-white ">
        <h2 className="text-3xl font-bold mb-4 text-primary">Software Development Course</h2>
        <p className="text-primary mb-6">
          Welcome to our comprehensive software development course! This course is designed to provide you with the skills and knowledge needed to become a successful software developer.
        </p>

        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeKEQlJuGhX5bI4dwgyreENm1MnmXQjqMx1fp01sEufECtsnw/viewform" className=" mt-6 hover:bg-white border bg-primary text-white border-primary hover:text-primary text-primary px-3 py-2 rounded-md text-base">
                                        Apply internship
                                    </a>
        
        {/* Feature Cards */}
        <FeatureCard
          title="Programming Languages"
          description="Learn languages such as JavaScript, Python, and Java."
        />
        <FeatureCard
          title="Front-end and Back-end"
          description="Explore both front-end and back-end development."
        />
        <FeatureCard
          title="Database Management"
          description="Understand database management and design."
        />
        <FeatureCard
          title="Real-world Projects"
          description="Build real-world projects to apply your skills."
        />
        <FeatureCard
          title="Collaborative Learning"
          description="Collaborate with peers in a hands-on learning environment."
        />
 <a href="https://docs.google.com/forms/d/e/1FAIpQLSeKEQlJuGhX5bI4dwgyreENm1MnmXQjqMx1fp01sEufECtsnw/viewform" className=" mt-6 hover:bg-primary border border-primary hover:text-white text-primary px-3 py-2 rounded-md text-base">
                                        Apply internship
                                    </a>
        <div className="mt-6">
          <p className="text-gray-600">
            Whether you are a beginner or have some coding experience, this course is tailored to help you excel in the dynamic field of software development.
          </p>
        </div>
      </div>
    </div>

           
        </section>
        
      </main>
      <Footer/>
    </>
  );
};

export default LandingPage;
